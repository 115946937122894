import React from "react";
import {Col, Card } from "react-bootstrap";

const IndustryPage = ({ title, content, externalLink }) => (
    <Col className="mb-4">
      <Card style={{ width: "18rem", border: "none", marginTop: '20px', maxHeight: "200px", overflowY: "auto" }} className="shadow-lg text-start rounded-l">
        <a className="card-link" href={externalLink} target="_blank" rel="noopener noreferrer">
          <Card.Body>
            <Card.Title>
              {title} <i className="bi bi-arrow-up-right-circle"></i>
            </Card.Title>
            <Card.Text>{content}</Card.Text>
          </Card.Body>
        </a>
      </Card>
    </Col>
  );

  export default IndustryPage;