import { useEffect } from "react";
import "./Login.css";

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauthSignIn() {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id:
      "482142206050-bcv5obloj2qsck18mhpi7g8onv66c4o7.apps.googleusercontent.com",
    redirect_uri: document.location.origin,
    response_type: "token",
    scope: "https://www.googleapis.com/auth/drive.metadata.readonly",
    include_granted_scopes: "true",
    state: "pass-through value",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const getUserData = (setUser) => {
  const urlToken =
    window.location.hash && window.location.hash.indexOf("access_token")
      ? window.location.hash?.split("access_token=")[1]?.split("&")[0]
      : "";
  const persistedToken =
    document.cookie.indexOf("access_token=") > -1
      ? document.cookie?.split("access_token=")[1]?.split("&")[0]
      : "";

  if (urlToken || persistedToken) {
    const expiry = !!urlToken ? window.location.hash?.split("expires_in=")[1]?.split("&")[0] : "0";
    const accessToken = urlToken || persistedToken;

    if (urlToken) {
      let date = new Date();
      date.setSeconds(date.getSeconds() + parseInt(expiry));
      const expireUTC = date.toUTCString();

      const newCookie = `${
        !!document.cookie ? document.cookie + ";" : ""
      }access_token=${urlToken};expires=${expireUTC}`;
      document.cookie = newCookie;
    }

    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `https://www.googleapis.com/drive/v3/about?&fields=user&access_token=${accessToken}`
    );

    xhr.onreadystatechange = function (e) {
      const res = xhr.response;
      if (!!res && JSON.parse(res)) {
        const data = JSON.parse(res);
        const user = !!data.user ? data.user : {};
        const email = user.emailAddress;

        if (!!email && email.endsWith("@quantummetric.com")) {
          setUser(user);
        }
      }
    };
    xhr.send(null);
  }
};

const GoogleSigninButton = () => {
  return (
    <button
      className="gsi-material-button"
      onClick={() => {
        oauthSignIn();
      }}
    >
      <div className="gsi-material-button-state"></div>
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            style={{ display: "block" }}
          >
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </div>
        <span className="gsi-material-button-contents">Sign in with Google</span>
        <span style={{ display: "none" }}>Sign in with Google</span>
      </div>
    </button>
  );
};

const hasAccessToken = () => {
  const persistedToken =
    document.cookie.indexOf("access_token=") > -1
      ? document.cookie?.split("access_token=")[1]?.split("&")[0]
      : "";
  return !!persistedToken;
};

const Login = ({ setUser }) => {
  useEffect(() => {
    getUserData(setUser);
    // oauthSignIn()
  }, []);
  return (
    <div className="container" style={{ magin: "0 auto", textAlign: "center" }}>
      {!hasAccessToken() ? (
        <>
          <h1>Login with Quantum email:</h1>
          <GoogleSigninButton />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Login;
